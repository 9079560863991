import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "home_subtitle": "Start an exciting adventure in the world GTA5RP",
      "captcha_title": "Verification of action",
      "payment_type": "Account replenishment",
      "donate_type": "Replenishment",
      "donate_replenishment": "REPLENISHMENT",
      "replenish": "Replenish",
      "replenish_account": "Replenish the account",
      "key_activation": "Key activation",
      "activate_key": "activate key",
      "server_title": "Server",
      "product_type": "Product type",
      "character_title": "Character",
      "amount_title": "Amount",
      "payment_amount": "Amount to be paid:",
      "buy_key": "Buy key",
      "enter_key": "Enter key",
      "activate": "Activate",
      "at_least": "At least",
      "min_donation_amount": "Donation amount at least",
      "only_mastercard": "Only mastercard",
      "only_visa": "Only visa",
      "payment_failed_alert": "If the payment failed, try again or use a card from another bank",
      "choose": "Choose",
      "payment_method": "Payment method",
      "or": "or",
      "list_of_services": "List of services:",
      "choose_character": "Choose character",
      "alert_sent_appeal": "The appeal has been sent!",
      "feedback": "Feedback",
      "your_login": "Your login",
      "req_login": "Enter login!",
      "your_email": "Your e-mail",
      "req_email": "Enter e-mail!",
      "incorrect_email": "Incorrect email",
      "error": "Error",
      "appeal_subject": "Subject of the appeal",
      "req_appeal_subject": "Enter the subject of the appeal!",
      "message_text": "Message text",
      "req_message_txt": "Enter message text!",
      "submit": "submit",
      "pack_start": "Start",
      "pack_pro": "Pro",
      "pack_authority": "Authority",
      "pack_boss": "Boss",
      "game_currency": "Game currency",
      "pack_accessories": "Random hat or accessory",
      "military_ticket": "Military ticket",
      "pack_vehicle_license": "License:",
      "pack_license": "License:",
      "pack_car": "Car",
      "pack_motorcycle": "Motorcycle",
      "pack_cargo_vehicles": "Cargo vehicles",
      "pack_aircrafts": "Aircrafts",
      "pack_water_vehicles": "Water vehicles",
      "pack_weapons": "Weapons",
      "pack_business": "Business",
      "pack_bonus": "Bonus",
      "pack_to_balance": "to the balance",
      "failed_state": "Something went wrong... Try again",
      "pending_fund_state": "Uploading character data!",
      "pending_success_state": "Switching to the payment form",
      "deposit_amount": "Deposit amount:",
      "enter_amount": "Enter amount!",
      "minimum_amount": "At least one cent!",
      "receiving": "You will get",
      "proceed_payment": "Proceed to payment",
      "uploading_payment_methods": "Uploading payment methods!",
      "uploading_donation_kits": "Uploading donation kits!",
      "uploading_character_list": "Uploading list of characters!",
      "uploading_servers_list": "Uploading a list of servers!",
      "switching_payment_form": "Switching to the payment form",
      "switching_servers_list": "Switching to the server list",
      "alert_no_characters": "You don\'t have any characters on the selected server yet!",
      "donation_kit": "Donation kit",
      "user_balance": "On the account:",
      "empty_characters": "No characters",
      "last_visit": "Last visit:",
      "buy": "Buy",
      "for": "for",
      "how_to_title": "How to",
      "begin_to_play": "begin to play",
      "buy_install": "Buy and install",
      "licensed_game": "licensed game",
      "steam_buy": "Buy on Steam",
      "join_official": "Join the official",
      "to_discord_server": "Discord server",
      "discord_server": "Discord server",
      "step_about_1": "Join the game",
      "step_about_2": "servers and play with thousands",
      "step_about_3": "of players online",
      "play_online": "Play online",
      "online": "Online",
      "in_queue": "in queue",
      "server_offline": "Server offline",
      "our_servers": "Our servers",
      "our_game_servers": "Our game servers",
      "confirm_title": "Confirmation",
      "confirm_code": "Confirmation code",
      "req_confirm": "Enter the confirmation code",
      "confirm": "Confirm",
      "authorization_title": "Authorization",
      "login": "Login",
      "req_login_fill": "Fill out field «Login»",
      "password": "Password",
      "req_password_fill": "Fill out field «Password»",
      "enter": "Enter",
      "req_terms": "Accept the terms of the agreement",
      "terms": "I accept ",
      "terms_accept": "the terms of the user agreement",
      "servers_unavailable": "Servers are unavailable",
      "pending_profile": "Loading a user profile",
      "donate": "Donat",
      "user_agreement": "User agreement",
      "personal_data_policy": "Personal data processing policy",
      "start_playing": "Start playing",
      "logging_in": "LOGGING IN",
      "balance": "Balance:",
      "leave": "Leave",
      "top_scroll": "Up",
      "adding_funds": "You are adding funds on GTA5RP GAME",
      "adding_funds_on_server": "You are adding funds on server",
      "servers_title_1": "Select the server",
      "servers_title_2": "And click «Replenishment»",
      "payment_failure": "AN ERROR HAS OCCURRED",
      "payment_status_text": "Try to pay the bill in another way or repeat later.",
      "payment_button": "Return to payment",
      "payment_success": "Successfully paid",
      "payment_success_title": "You have successfully paid the bill",
      "payment_success_text": "Log in to the server and check your balance (F10 - Shop)",
      "join_discord": "Join Discord",
    }
  },
  ru: {
    translation: {
      "home_subtitle": "Начни увлекательное приключение в мире GTA5RP",
      "captcha_title": "Проверка действия",
      "payment_type": "Пополнение счета",
      "donate_type": "Пополнение",
      "donate_replenishment": "ПОПОЛНЕНИЕ",
      "replenish": "Пополнить",
      "replenish_account": "Пополнить счет",
      "key_activation": "Активация ключа",
      "activate_key": "активировать ключ",
      "server_title": "Сервер",
      "product_type": "Тип товара",
      "character_title": "Персонаж",
      "amount_title": "Количество",
      "payment_amount": "Сумма к оплате:",
      "buy_key": "Купить ключ",
      "enter_key": "Введите ключ",
      "activate": "Активировать",
      "at_least": "Минимум",
      "min_donation_amount": "Минимальная сумма доната составляет",
      "only_mastercard": "Только mastercard карты",
      "only_visa": "Только visa карты",
      "payment_failed_alert": "Если оплата не прошла - повторите попытку или карту другого банка",
      "choose": "Выберите",
      "payment_method": "Способ оплаты",
      "or": "или",
      "list_of_services": "Список услуг:",
      "choose_character": "Выберите персонажа",
      "alert_sent_appeal": "Обращение отправлено!",
      "feedback": "Обратная связь",
      "your_login": "Ваш логин",
      "req_login": "Введите логин!",
      "your_email": "Ваш e-mail",
      "req_email": "Введите e-mail!",
      "incorrect_email": "Некорректный email",
      "error": "Ошибка",
      "appeal_subject": "Тема обращения",
      "req_appeal_subject": "Введите тему обращения!",
      "message_text": "Текст сообщения",
      "req_message_txt": "Введите текст сообщения!",
      "submit": "отправить",
      "pack_start": "Стартовый",
      "pack_pro": "Профи",
      "pack_authority": "Авторитет",
      "pack_boss": "Босс",
      "game_currency": "Игровая валюта",
      "pack_accessories": "Случайная шапка или аксессуар",
      "military_ticket": "Военный билет",
      "pack_vehicle_license": "Права на:",
      "pack_license": "Лицензия на:",
      "pack_car": "Автомобиль",
      "pack_motorcycle": "Мотоциклы",
      "pack_cargo_vehicles": "Грузовые Т/С",
      "pack_aircrafts": "Самолеты",
      "pack_water_vehicles": "Водные Т/С",
      "pack_weapons": "Оружие",
      "pack_business": "Бизнес",
      "pack_bonus": "Бонус",
      "pack_to_balance": "на баланс",
      "failed_state": "Что-то пошло не так... Попробуйте ещё раз",
      "pending_fund_state": "Загружаем данные персонажа!",
      "pending_success_state": "Переход на форму оплаты",
      "deposit_amount": "Сумма пополнения:",
      "enter_amount": "Введите сумму!",
      "minimum_amount": "Не менее одного рубля!",
      "receiving": "Вы получите",
      "proceed_payment": "Перейти к оплате",
      "uploading_payment_methods": "Загружаем способы оплаты!",
      "uploading_donation_kits": "Загружаем донат наборы!",
      "uploading_character_list": "Загружаем список персонажей!",
      "uploading_servers_list": "Загружаем список серверов!",
      "switching_payment_form": "Переход на форму оплаты",
      "switching_servers_list": "Переход на список серверов",
      "alert_no_characters": "У вас ещё нет персонажей на выбранном сервере!",
      "donation_kit": "Донат набор",
      "user_balance": "На счете:",
      "empty_characters": "Персонажей нет",
      "last_visit": "Последний вход:",
      "buy": "Купить",
      "for": "за",
      "how_to_title": "Как начать",
      "begin_to_play": "играть",
      "buy_install": "Купи и установи",
      "licensed_game": "лицензионную игру",
      "steam_buy": "Купить в Steam",
      "join_official": "Подключись к официальному",
      "to_discord_server": "серверу в Discord",
      "discord_server": "Discord сервер",
      "step_about_1": "Подключись к игровым",
      "step_about_2": "серверам и играй с тысячами",
      "step_about_3": "игроков онлайн",
      "play_online": "Играть онлайн",
      "online": "Онлайн",
      "in_queue": "в очереди",
      "server_offline": "Сервер оффлайн",
      "our_servers": "Наши сервера",
      "our_game_servers": "Наши игровые сервера",
      "confirm_title": "Подтверждение",
      "confirm_code": "Код подтверждения",
      "req_confirm": "Введите код подтверждения",
      "confirm": "Подтвердить",
      "authorization_title": "Авторизация",
      "login": "Логин",
      "req_login_fill": "Заполните поле «Логин»",
      "password": "Пароль",
      "req_password_fill": "Заполните поле «Пароль»",
      "enter": "Войти",
      "req_terms": "Примите условия соглашения",
      "terms": "Принимаю ",
      "terms_accept": "условия пользовательского соглашения",
      "servers_unavailable": "Сервера недоступны",
      "pending_profile": "Загрузка профиля пользователя",
      "donate": "Донат",
      "user_agreement": "Пользовательское соглашение",
      "personal_data_policy": "Политика обработки персональных данных",
      "start_playing": "Начать играть",
      "logging_in": "ВЫПОЛНЯЕМ ВХОД",
      "balance": "Баланс:",
      "leave": "Выйти",
      "top_scroll": "Наверх",
      "adding_funds": "Вы пополняете счет на GTA5RP GAME",
      "adding_funds_on_server": "Вы пополняете счет на сервере",
      "servers_title_1": "Выберите сервер",
      "servers_title_2": "И нажмите «Пополнить счет»",
      "payment_failure": "ПРОИЗОШЛА ОШИБКА",
      "payment_status_text": "Попробуйте оплатить счет другим способом или повторите позже.",
      "payment_button": "Вернуться к оплате",
      "payment_success": "Успешно оплачено",
      "payment_success_title": "Вы успешно оплатили счет",
      "payment_success_text": "Зайдите на сервер и проверьте ваш баланс (F10 - Магазин)",
      "join_discord": "Подключиться в Discord",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;